<template>
  <div v-if="location">
    <modal-edit-social
      v-if="showEditSocial"
      :showModal="showEditSocial"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditSocial">
      <template v-slot:title>Social accounts</template>
    </ui-section-header>

    <table class="table is-fullwidth is-narrow is-hoverable">
      <tbody>
        <tr
          v-for="(account, index) in location.SocialMediaAccounts"
          :key="index"
        >
          <td width="75">{{ getSocialName(account.Name) }}</td>
          <td class="has-text-right">{{ account.Value }}</td>
          <td width="25">
            <a
              v-if="account.Value"
              :href="account.Url.replace('##value##', account.Value)"
              target="_blank"
              class="has-icon"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'link']" />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditSocial = () =>
  import('@/components/Locations/ModalEditLocationSocial')

export default {
  components: {
    'modal-edit-social': ModalEditSocial,
  },

  props: {},

  data() {
    return {
      locationId: Number(this.$route.params.locationId),
      showEditSocial: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {},

  methods: {
    getSocialName(social) {
      let name = ''

      switch (social.trim()) {
        case 'twitter_name':
          name = 'Twitter'
          break
        case 'youtube_channel':
          name = 'Youtube'
          break
        case 'facebook_page_name':
          name = 'Facebook'
          break
        case 'linkedIn_group_name':
          name = 'LinkedIn'
          break
        case 'instagram_handle':
          name = 'Instagram'
          break
        default:
          name = ''
      }

      return name
    },

    setShowEditSocial() {
      this.showEditSocial = true
    },

    hideModal() {
      this.showEditSocial = false
    },
  },
}
</script>

<style></style>
